<template>
	<div>
		<p>in process...</p>
	</div>
</template>

<script>

export default {
	async mounted() {
		this.$store.dispatch('wizard/initWizard', {
			query: this.$route.query,
			refPage: this.$route.params.refPage,
		});
	},
};
</script>
